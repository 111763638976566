import { ReactNode } from 'react';
import Link from 'next/link';

import { PageListItem } from 'data/Page';
import ContentRouteValidator from 'components/content/ContentRouteValidator';

function hasAttribute(attr: string, attributes?: string[]): boolean {
  return !!attributes?.find(a => a === attr);
}

interface PageContentProps {
  children: ReactNode;
  sidebar?: boolean;
  before?: PageListItem;
  after?: PageListItem;
  attributes?: string[];
  embed?: ReactNode;
}

function PageContent({
  children,
  sidebar,
  before,
  after,
  attributes,
  embed,
}: PageContentProps) {
  return (
    <div className={`PageContent`}>
      {embed && <div className="PageContent__embed">{embed}</div>}
      {children}
      {hasAttribute('RouteValidator', attributes) && <ContentRouteValidator />}
      <div className="PageContent__footer">
        {before && (
          <Link href={before.locale.path} passHref legacyBehavior>
            <a className="PageContent__footerPage PageContent__footerPage--previous">
              <div className="PageContent__footerPageTitle">
                {before.locale.title}
              </div>
              <div className="PageContent__footerPageSubtitle">
                {before.locale.subtitle}
              </div>
            </a>
          </Link>
        )}
        {after && (
          <Link href={after.locale.path} passHref legacyBehavior>
            <a className="PageContent__footerPage PageContent__footerPage--next">
              <div className="PageContent__footerPageTitle">
                {after.locale.title}
              </div>
              <div className="PageContent__footerPageSubtitle">
                {after.locale.subtitle}
              </div>
            </a>
          </Link>
        )}
      </div>
    </div>
  );
}

export default PageContent;
